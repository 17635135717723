<template>
    <div>
        <van-dialog v-model="showDialog" title="密码" show-cancel-button @confirm="confirmBtn" @cancel="cancelBtn">
            <van-field class="psssword-input" type="password" v-model="pass" placeholder="请输入密码" />
        </van-dialog>
    </div>
</template>
<script>

import { verifyPass } from '@/api';

export default {
    name: 'VerifyPass',
    props: {
    },
    data() {
        return {
            pass: "",
            showDialog: true
        }
    },
    methods: {
         // 密码确认
         confirmBtn(){
            this.verifyPassword()
        },
        // 密码取消
        cancelBtn(){
            this.$router.go(-1);
        },
         // 密码验证
         async verifyPassword(){
            try {
                const { code, data } = await verifyPass({pass: this.pass});
                this.$store.dispatch('setPass', {pass: this.pass})
                this.showDialog = false
                this.$emit('pass-true')
            } catch (e) {
                this.showDialog = true
            }
        },
    },
    created(){
        let pass = this.$store.state.pass
        if(pass) {
            this.showDialog = false
            this.pass = pass
            this.verifyPassword()
        }
    }
}
  </script>
<style scoped>
    .psssword-input{
        text-align: center;
    }
</style>